@import 'main.scss';
.chipButton {
  font-family: var(--font-avant);
  color: var(--White, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  padding: 10px 22px;
  text-transform: uppercase;
  z-index: 30;
  position: relative;
  border-radius: 500px;
  width: fit-content;
  transition: 0.5s ease;
  overflow: hidden;
  box-sizing: border-box;
  letter-spacing: 0.7px;
  cursor: none;
  pointer-events: all;

  &::before {
    content: "";
    position: absolute;
    bottom: -250px;
    width: 100%;
    height: 223px;
    z-index: -1;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
    left: 0;
  }

  &.outline {
    color: var(--primary, #17082f);
    border: 1px solid var(--primary, #17082f);

    &::before {
      background-color: var(--primary);
    }

    @media screen and (hover: hover) {
      &:hover {
        color: var(--white);
        background-color: var(--primary);
        transition: 0.3s ease-in-out;

        &::before {
          transform: translateY(-60%);
          transition: 0.3s ease-in-out;
        }
      }
    }
  }

  &.accent {
    display: none;
    color: var(--primary);
    background-color: var(--accent, #ffb800);

    @media screen and (min-width: 1000px) {
      display: flex;
    }

    &::before {
      background-color: #ffd600;
    }

    @media screen and (hover: hover) {
      &:hover {
        background-color: #ffd600;
        transition: 0.3s ease-in-out;

        &::before {
          transform: translateY(-60%);
          transition: 0.3s ease-in-out;
        }
      }
    }
  }

  &.active {
    background-color: var(--secondary, #af2eff);
    color: #fff;

    @media screen and (min-width: 1000px) {
      display: none;
    }

    &::before {
      background-color: var(--white);
    }

    @media screen and (hover: hover) {
      &:hover {
        color: black;
        background-color: var(--white);
        transition: 0.3s ease-in-out;

        &::before {
          transform: translateY(-60%);
          transition: 0.3s ease-in-out;
        }
      }
    }

    &.hidden {
      display: none;

      @media screen and (min-width: 1000px) {
        display: flex;
      }
    }
  }

  &.base {
    background-color: var(--white, #fff);
    color: var(--primary, #17082f);

    &::before {
      background-color: #ffd600;
    }

    @media screen and (hover: hover) {
      &:hover {
        color: var(--primary, #17082f);
        background-color: #ffd600;
        transition: 0.3s ease-in-out;

        &::before {
          transform: translateY(-60%);
          transition: 0.3s ease-in-out;
        }
      }
    }
  }
}
