@import 'main.scss';
.buttonLanguage {
  font-family: var(--font-sora);
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: var(--white);
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
  margin-left: 0px;

  &:disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }

  @media screen and (min-width: 1000px) {
    opacity: 0.7;
    font-size: 18px;
    line-height: 23px;

    &:hover {
      opacity: 1;
    }
  }

  @media screen and (min-width: 1200px) {
    font-size: 20px;
    line-height: 28px;
  }
}
